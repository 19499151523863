/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-danger */
import * as React from 'react';
import { useState, FC } from 'react';
import Slider from 'react-slick';

import { ImageGallery } from '../../../ui/imageGallery';
import { Image } from '../../../ui/image';

const DefaultSlider: FC<{
  classes?: string;
  children?: any;
}> = ({ classes, children }) => {
  const settings = {
    variableWidth: true,
    dots: true,
    autoplay: false,
    infinite: false,
    arrows: false,
    speed: 1000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    swipeToSlide: true,
    focusOnSelect: true,
  };
  return (
    <Slider
      {...settings}
      className={`${classes} round_dots  img_map_slider  features_slider`}
    >
      {children}
    </Slider>
  );
};
DefaultSlider.defaultProps = {
  classes: ``,
  children: {},
};
const ImageMapTwo: FC<{
  data: any;
}> = ({ data }) => {
  const [className, setClassN] = useState(``);
  const [close, setClose] = useState({
    'online-booking': 'off',
    'online-calendar': 'off',
    pos: 'off',
    'email-marketing': 'off',
    'customer-managment': 'off',
    'card-reader': 'off',
  });
  const [, updateState] = useState([]);
  const forceUpdate = () => updateState([]);

  const toggleHandler = (name) => (e: any) => {
    if (className !== name && close[className] === 'on') {
      close[className] = 'off';
      close[name] = 'on';
      setClassN(name);
      setClose(close);
    } else if (close[name] === 'on' && name === className) {
      close[name] = 'off';
      setClassN(name);
      setClose(close);
      forceUpdate();
    } else {
      setClassN(name);
      close[name] = 'on';
      setClose(close);
      forceUpdate();
    }
  };

  return (
    <>
      <section className="section section-navigation versionB new-image-map has-text-centered  is-hidden-touch">
        <div className="columns is-centered">
          <div className="column is-7 is-12-mobile">
            <h3 className="title title-large text-black has-text-centered">
              {data.title}
            </h3>
          </div>
        </div>
        <div className="inner-section container">
          <ImageGallery
            name={data.img}
            alt="navigation overlay"
            className="nav-overlay"
          />
          {Object.keys(data.navElements).map((key) => {
            const {
              classN,
              title,
              text,
              badge,
              link,
              link_text,
            } = data.navElements[key];

            return (
              typeof data.navElements[key] === 'object' && (
                <div key={classN}>
                  {close[classN] !== `on` ? (
                    <div
                      className={`nav-tooltip ${classN} ${close[classN]}`}
                      role="button"
                      onClick={toggleHandler(classN)}
                    >
                      <ImageGallery
                        name="circle_plus_white.svg"
                        alt="angle-left icon"
                        className="nav-icon white"
                      />
                    </div>
                  ) : (
                    <div
                      className={`nav-tooltip ${classN} inside-${close[classN]} ${close[classN]}`}
                      role="button"
                      onClick={toggleHandler(classN)}
                    >
                      <ImageGallery
                        name="circle_plus_white.svg"
                        alt="angle-left icon"
                        className="nav-icon white"
                      />
                      <div
                        className="nav-tooltip-wrapper has-text-left"
                        id="tooltip-wrapper"
                      >
                        <h4 className="title title-small">
                          {badge && (
                            <span className="text-is-green">{badge}</span>
                          )}
                          {title}
                        </h4>

                        <p
                          dangerouslySetInnerHTML={{
                            __html: text,
                          }}
                        />
                        {link && (
                          <a href={link}>
                            {link_text}
                            <Image
                              name="angle-right.svg"
                              alt="angle-right icon"
                              className="inline-icon angle inline"
                            />
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )
            );
          })}
        </div>
      </section>
      <section className="section section-navigation versionB new-image-map has-text-centered  is-visible-touch is-hidden-desktop">
        <div className="columns is-centered">
          <div className="column is-7 is-12-mobile">
            <h3 className="title title-large text-black has-text-centered">
              {data.title}
            </h3>
          </div>
        </div>
        <div className="inner-section ">
          <ImageGallery
            name={data.img}
            alt="navigation overlay"
            className="nav-overlay"
          />
          <DefaultSlider classes="is-visible-mobile is-hidden-tablet is-hidden-desktop">
            {Object.keys(data.navElements).map((key, i) => {
              const { text, title } = data.navElements[key];
              return (
                <div className="why_card">
                  <p className=" text-bold mt-4 mb-0">{title}</p>
                  <p
                    className="  mt-4 mb-0"
                    dangerouslySetInnerHTML={{
                      __html: text,
                    }}
                  />
                </div>
              );
            })}
          </DefaultSlider>
        </div>
      </section>
    </>
  );
};

export default ImageMapTwo;
