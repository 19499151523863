import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobileOnly, isDesktop } from 'react-device-detect';

import Slider from 'react-slick';
import { Image } from '../ui/image';

import { ImageGallery } from '../ui/imageGallery';

import Button from '../ui/button';
import pages from '../../utils/pages';

const { getPageUrl } = pages;
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span
      className={`${className} custom_slick_arrow `}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <ImageGallery
        name="chevron_right_circle_black"
        alt="arrow next"
        className="arrow arrow-right"
      />
    </span>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span
      className={`custom_slick_arrow ${className}`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <ImageGallery
        name="chevron_left_circle_black"
        className="arrow arrow-left"
        alt="arrow left"
      />
    </span>
  );
}

const DefaultSlider: FC<{
  classes?: string;
  children?: any;
}> = ({ classes, children }) => {
  const settings = {
    variableWidth: true,
    dots: false,
    autoplay: false,
    infinite: true,
    arrows: true,
    speed: 1000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <Slider {...settings} className={`${classes}   features_slider`}>
      {children}
    </Slider>
  );
};
DefaultSlider.defaultProps = {
  classes: ``,
  children: {},
};
const FeatureSlider: FC<{
  slides: any;
  classes?: string;
  title: string;
}> = ({ title, slides, classes }) => {
  const { t } = useTranslation('general');
  return (
    <section className="feature_section">
      <div className="sections-title columns is-centered">
        <div className="column is-8 ">
          <h2 className="title title-large is-marginless text-centered text-black">
            {title}
          </h2>
        </div>
      </div>
      {slides && (
        <DefaultSlider classes="is-hidden-mobile is-visible-desktop">
          {Object.keys(slides).map((key) => {
            const page = getPageUrl(slides[key].key, 'de');
            return (
              <div
                key={key}
                className=" has-background-white  featurer_slider_card"
              >
                <ImageGallery
                  name={slides[key].media}
                  alt={slides[key].text.title}
                  className="feature-image image-centered "
                  maxWidth="1058"
                  height="458"
                />
                <h2 className="title title-medium section-title text-black">
                  {slides[key].title}
                </h2>
                <p className="text-medium">{slides[key].text} </p>
                <Button color="exclusive" link to={page && page.url}>
                  {t('learnMore')}
                  <Image
                    name="angle-right.svg"
                    alt="angle-left icon"
                    className="inline-icon angle inline"
                  />
                </Button>
              </div>
            );
          })}
        </DefaultSlider>
      )}
      {slides && (
        <div className="feature_cards is-hidden-desktop is-visible-mobile is-hidden-tablet">
          {Object.keys(slides).map((key, i) => {
            const page = getPageUrl(slides[key].key, 'de');
            return (
              <div key={key} className=" has-background-white featurer_card">
                <ImageGallery
                  name={slides[key].media}
                  alt={slides[key].text.title}
                  className="feature-image image-centered "
                  maxWidth="1058"
                  height="458"
                />
                <h2 className="title title-medium section-title text-black">
                  {slides[key].title}
                </h2>

                <p className="text-medium">{slides[key].text} </p>
                <Button color="exclusive" link to={page && page.url}>
                  {t('learnMore')}
                  <Image
                    name="angle-right.svg"
                    alt="angle-left icon"
                    className="inline-icon angle inline"
                  />
                </Button>
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
};

FeatureSlider.defaultProps = {
  classes: ``,
};
export default FeatureSlider;
