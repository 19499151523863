/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
//@ts-nocheck
import * as React from 'react';
import { FC, useEffect, useState, useRef } from 'react';
import { Link } from 'gatsby';
import FadeIn from 'react-fade-in';
import { Animated } from 'react-animated-css';
import ScrollAnimation from 'react-animate-on-scroll';
import { isMobileOnly, isMobile, isDesktop } from 'react-device-detect';
import Slider from 'react-slick';

import { ImageGallery } from '../../../ui/imageGallery';
import { Image } from '../../../ui/image';

const DefaultSlider: FC<{
  classes?: string;
  children?: any;
}> = ({ classes, children }) => {
  const settings = {
    variableWidth: true,
    dots: true,
    autoplay: false,
    infinite: false,
    arrows: false,
    speed: 1000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    swipeToSlide: true,
    focusOnSelect: true,
  };
  return (
    <Slider {...settings} className={`${classes} round_dots   features_slider`}>
      {children}
    </Slider>
  );
};
DefaultSlider.defaultProps = {
  classes: ``,
  children: {},
};
const SectionWhy: FC<{ whyShore: any }> = ({ whyShore }) => {
  const [toggle, setToggle] = useState<number>(0);
  const timerId = useRef<NodeJS.Timeout>();
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  // Automatically loop through the elements every 2 seconds only if mouse is not over the section
  useEffect(() => {
    if (!isHover &&  whyShore.reasons) {
      timerId.current = setInterval(() => {
        setToggle((toggle + 1) % Object.keys(whyShore.reasons).length);
      }, 2000);
    }
    return () => clearInterval(timerId.current);
  }, [toggle, isHover]);

  return (
    <section
      className={` section section-whyShore`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-9 is-12-mobile">
            <h3
              className="title title-large text-black has-text-centered"
              dangerouslySetInnerHTML={{
                __html: `${whyShore.title}`,
              }}
            />
          </div>
        </div>
        {whyShore.reasons && (
          <DefaultSlider classes="is-visible-mobile is-hidden-tablet is-hidden-desktop">
            {Object.keys(whyShore.reasons).map((key, i) => {
              const { text, image } = whyShore.reasons[key];
              return (
                <div className="why_card">
                  <FadeIn>
                    <ImageGallery
                      name={image}
                      alt={text}
                      className="whyShoreImageSlide"
                    />
                  </FadeIn>
                  <p className="text-large mt-4 mb-0">{text}</p>
                </div>
              );
            })}
          </DefaultSlider>
        )}
        {whyShore.reasons && (
          <>
            <div className="columns is-centered is-hidden-mobile is-visible-desktop">
              <div className="column is-5 is-12-mobile list-why">
                {Object.keys(whyShore.reasons).map((key, i) => {
                  const { text } = whyShore.reasons[key];
                  return (
                    //@ts-ignore
                    <Animated
                      animationIn="bounceInLeft"
                      animationOut="fadeOut"
                      isVisible
                    >
                      <div
                        key={key}
                        className={`qrow ${toggle === i && 'active'}`}
                        onClick={() => setToggle(i)}
                        onKeyDown={() => setToggle(i)}
                        onMouseOver={() => setToggle(i)}
                        onFocus={() => setToggle(i)}
                      >
                        <span className="number_badge"> {i + 1}</span>
                        <p className="text-medium">{text}</p>
                      </div>
                    </Animated>
                  );
                })}
              </div>
              <div className="column is-5 is-12-mobile answers is-vcentered">
                {Object.keys(whyShore.reasons).map((key, i) => {
                  const { image, text } = whyShore.reasons[key];
                  return toggle === i ? (
                    <ImageGallery
                      name={image}
                      alt={text}
                      className="whyShoreImage"
                      width={480}
                    />
                  ) : null;
                })}
              </div>
            </div>
          </>
        )}
     {whyShore.cta_key &&   <div className="columns is-centered">
          <div className="column is-9 is-12-mobile has-text-centered">
            <p className="text-large mt-4 mb-0 is-bold p-2 is-hidden-desktop is-visible-mobile why_roi_text">
              {whyShore.figureOutWhy_Mobile_pre}
            </p>
      
            <Link className="why_roi" to="#toproi" id="uniform-font-size">
              {!isMobileOnly
                ? whyShore.figureOutWhy
                : whyShore.figureOutWhy_Mobile}
              <Image
                name="arrow_right_long.svg"
                alt="angle-left icon"
                className="inline-icon angle inline"
              />
            </Link>
          </div>
        </div>}
      </div>
    </section>
  );
};

export { SectionWhy };
