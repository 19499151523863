/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react';
import { FC, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizedPageProps, IColumnList } from '../utils/types';
import ConversionSection from '../components/sections/conversion';
import QuoteSlider from '../components/sections/quote';
import KeyAccounts from '../components/sections/key-accounts';

import { HeroHomeC, HeroHomeB, HeroHomeCampaingn } from '../components/partials/common/hero';

import RoiSection from '../components/sections/roi-section';

import { ImageMapTwo } from '../components/partials/home/image-map-section';

import { Layout } from '../components/partials/common';
import FeatureSlider from '../components/sections/feature-slider';
import { SectionWhy } from '../components/partials/home/section-why-shore';
import { Modal } from '../components/partials/common/elements/Modal';

import { SEO } from '../components/seo';
// @ts-ignore
import HubspotContactForm from '../components/partials/widgets/Roi-HS';

const IndexPage: FC<LocalizedPageProps> = ({ pageContext }) => {
  const localKey = `${pageContext.key}`;
  const { t } = useTranslation(['home', 'home-new']);
  const { lang, alternateUrls } = pageContext;
  const [modal_visibility, toogleModal] = useState(false);

  /* A/B Test */
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      toogleModal(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const hero = t('hero', {
    returnObjects: true,
    ns: 'home-new',
  });
  const navigation_b = t('navigation', {
    returnObjects: true,
    ns: 'home-new',
  });

  const sectionsB: IColumnList = t('sections', {
    ns: 'home-new',
    returnObjects: true,
  });
  const whyShore: IColumnList = t('whyShore', {
    ns: 'home-new',
    returnObjects: true,
  });

  const quoteb: IColumnList = t('quote', {
    ns: 'home-new',
    returnObjects: true,
  });

  const sectionTitleB = t('sectionsTitle', {
    ns: 'home-new',
  });
  return (
    <>
      <Layout
        pageContext={pageContext}
        isHomePage
        navClasses="default"
        mainClasses={`old overlay_nav `}
      >
        <SEO
          lang={lang}
          title={t('meta.title')}
          description={t('meta.description')}
          alternateUrls={alternateUrls}
        />
        {lang === 'fr' && (
            <HeroHomeB translation={t} lang={lang} toogleModal={toogleModal} />
          )}
          { lang === 'en-US' && (
            <HeroHomeB translation={t} lang={lang} toogleModal={toogleModal} />
          )}
             { lang === 'es' && (
            <HeroHomeB translation={t} lang={lang} toogleModal={toogleModal} />
          )}
        {lang !== 'fr' && lang !== 'en-US'  && lang !== 'es'  && (
          <HeroHomeCampaingn data={hero} lang={lang} />
        )}
        <SectionWhy whyShore={whyShore} />
        <KeyAccounts localKey={localKey} />
        <ImageMapTwo data={navigation_b} />

        <FeatureSlider slides={sectionsB} title={sectionTitleB} />

        {lang !== 'fr' && lang !== 'en-US' && (
          <RoiSection lang={lang} classes="has-background-grey" />
        )}

        <QuoteSlider data={quoteb} />
        <ConversionSection ctaUrl={t('conversion.ctaUrl')} />
      </Layout>
      <Modal
        classes="video-modal  mt-5 pt-5"
        toogleModal={toogleModal}
        visible={modal_visibility}
      >
        <div className="container mt-5">
          <div className=" columns is-centered mt-5 pt-5">
            <div className="column is-5 is-12-mobile">
              <video
                className="modal_video"
                controls
                width="450"
                poster="https://res.cloudinary.com/shore-gmbh/image/upload/v1705663882/videos/_thumbnail_website_01_bm.png"
              >
                <source
                  src="https://res.cloudinary.com/shore-gmbh/video/upload/v1705315718/videos/booking.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="column is-5 is-12-mobile">
              <video
                className="modal_video"
                controls
                poster="https://res.cloudinary.com/shore-gmbh/image/upload/v1705663889/videos/_thumbnail_website_01_pos.png"
              >
                <source
                  src="https://res.cloudinary.com/shore-gmbh/video/upload/v1705316023/videos/pos.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IndexPage;
