import * as React from 'react';
import { FC } from 'react';
import images from '../../utils/store/images';
import { ImageGallery } from '../ui/imageGallery';

const KeyAccounts: FC<{
  localKey: any;
  classes?: any;
}> = ({ localKey, classes }) => {
  return (
    <section
      className={`${
        classes && classes
      } section section-cards section-keyaccounts `}
    >
      <div className="columns is-centered client-logos">
        {images[localKey] &&
          Object.keys(images[localKey]).map((key) => {
            return (
              <ImageGallery
                key={key}
                name={images[localKey][key].img}
                width={images[localKey][key].width}
                alt={images[localKey][key].alt}
                className={`${images[localKey][key].img} client-logo `}
              />
            );
          })}
      </div>
    </section>
  );
};
KeyAccounts.defaultProps = {
  classes: ``,
};

export default KeyAccounts;
