/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-danger */
import * as React from 'react';
import * as rdd from 'react-device-detect';
import { TFunction } from 'i18next';
import { useState, FC } from 'react';
import { ImageGallery } from '../../../ui/imageGallery';
import { Image } from '../../../ui/image';

const ImageMapOne: FC<{
  translation: TFunction;
}> = ({ translation }) => {
  const navigation = translation('navigation.navElements', {
    returnObjects: true,
  });

  const [className, setClassN] = useState(``);
  const [close, setClose] = useState({
    'online-booking': 'off',
    'online-calendar': 'off',
    pos: 'off',
    'email-marketing': 'off',
    'customer-managment': 'off',
    'card-reader': 'off',
  });
  const [, updateState] = useState([]);
  const forceUpdate = () => updateState([]);

  const toggleHandler = (name) => (e: any) => {
    if (className !== name && close[className] === 'on') {
      close[className] = 'off';
      close[name] = 'on';
      setClassN(name);
      setClose(close);
    } else if (close[name] === 'on' && name === className) {
      close[name] = 'off';
      setClassN(name);
      setClose(close);
      forceUpdate();
    } else {
      setClassN(name);
      close[name] = 'on';
      setClose(close);
      forceUpdate();
    }
  };

  return (
    <section className="section section-navigation versionA has-text-centered has-background-grey is-hidden-touch">
      {rdd.isDesktop && (
        <div className="container column is-9">
          <ImageGallery
            name={translation('navigation.img')}
            alt="navigation overlay"
            className="nav-overlay"
            maxWidth="1100"
            height="551"
          />
          {Object.keys(navigation).map((key) => {
            const { classN, title, text, badge, link, link_text } = navigation[
              key
            ];

            return (
              typeof navigation[key] === 'object' && (
                <div key={classN}>
                  {close[classN] !== `on` ? (
                    <div
                      className={`nav-tooltip ${classN} ${close[classN]}`}
                      role="button"
                      onClick={toggleHandler(classN)}
                    >
                      <Image
                        name="circle_plus.svg"
                        alt="angle-left icon"
                        className={`nav-icon `}
                      />
                    </div>
                  ) : (
                    <div
                      className={`nav-tooltip ${classN} inside-${close[classN]} ${close[classN]}`}
                      role="button"
                      onClick={toggleHandler(classN)}
                    >
                      <Image
                        name="plain_circle.svg"
                        alt="angle-left icon"
                        className={`nav-icon `}
                      />
                      <div
                        className="nav-tooltip-wrapper has-text-left"
                        id="tooltip-wrapper"
                      >
                        <h4 className="title title-small">
                          {badge && (
                            <span className="text-is-green">{badge}</span>
                          )}
                          {title}
                        </h4>

                        <p
                          dangerouslySetInnerHTML={{
                            __html: text,
                          }}
                        />
                        {link && (
                          <a href={link}>
                            {link_text}
                            <Image
                              name="angle-right.svg"
                              alt="angle-right icon"
                              className="inline-icon angle inline"
                            />
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )
            );
          })}
        </div>
      )}
    </section>
  );
};

export default ImageMapOne;
